import {useState} from 'react';
import {TextField, Button, MenuItem, Select, FormControl, CircularProgress, Typography, Box} from '@mui/material';
import icon from '../../../public/favicon.svg'

function ContactForm() {
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [complete, setComplete] = useState(false);

    const [formData, setFormData] = useState({
        school: '',
        name: '',
        age: '',
        email: '',
        phoneNumber: '',
        message: '',
        origin: '',
    });

    const handleChange = (event: { target: { name: string; value: string; }; }) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setFormSubmitting(true);
        try {
            formData.origin = localStorage.getItem('o') ?? '';
            const response = await fetch('https://z36yqxxizkk52vzf3bn4dyeqei0fnxsn.lambda-url.us-east-1.on.aws/', {
                method: 'POST',
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                setComplete(true);
            } else {
                setErrorMessage('There has been an error while attempting to submit this form. Please try again later.');
            }

            setFormSubmitting(false);
        } catch (error) {
            setErrorMessage('An error has occurred while attempting to submit this form. Please try again later.');
            setFormSubmitting(false);
        }
    };

    return (
        complete ?
            <Box sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Box component={"img"} src={icon} sx={{height: 100, filter: 'brightness(0%)'}}/>
                <br/>
                <Typography
                    variant={"h4"} sx={{fontWeight: 'bold',}}>Thank you for reaching out!<p/>We will be in contact
                    soon.</Typography>
            </Box> :
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                    <Select
                        name="school"
                        value={formData.school}
                        onChange={handleChange}
                        displayEmpty
                        required
                        sx={{textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)'}}
                    >
                        {/*UPDATING THIS LIST REQUIRES AN UPDATE TO THE LAMBDA FUNCTION FOR SENDING EMAILS*/}
                        <MenuItem sx={{display: 'none'}} value="" disabled>Select School *</MenuItem>
                        <MenuItem value="prodigy-creekside">Prodigy at Creekside - Riverview, FL</MenuItem>
                        <MenuItem value="prodigy-epperson">Prodigy at Epperson - Wesley Chapel, FL</MenuItem>
                        <MenuItem value="prodigy-keysgate">Prodigy at Keys Gate - Homestead, FL</MenuItem>
                        <MenuItem value="prodigy-tapestry">Prodigy at Tapestry - Kissimmee, FL</MenuItem>
                        <MenuItem value="prodigy-treaty-oaks">Prodigy at Treaty Oaks - St Augustine, FL</MenuItem>
                        <MenuItem value="prodigy-winthrop">Prodigy at Winthrop - Riverview, FL</MenuItem>
                        <MenuItem value="prodigy-wellen-park">Prodigy at Wellen Park - Venice, FL</MenuItem>
                        <MenuItem value="prodigy-wellington">Prodigy at Wellington - Lake Worth, FL</MenuItem>
                        <MenuItem value="prodigy-acadiana">Prodigy at Acadiana - Youngsville, LA</MenuItem>
                        <MenuItem value="prodigy-berkeley">Prodigy at Berkeley - Summerville, SC</MenuItem>
                        <MenuItem value="prodigy-settlement">Prodigy at the Settlement - Simpsonville, SC</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    name="name"
                    label="Child Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    fullWidth
                    margin="normal"
                    sx={{textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)'}}
                />
                <TextField
                    name="age"
                    label="Child Age"
                    value={formData.age}
                    onChange={handleChange}
                    required
                    fullWidth
                    margin="normal"
                    sx={{textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)'}}
                />
                <TextField
                    name="email"
                    label="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    fullWidth
                    margin="normal"
                    sx={{textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)'}}
                />
                <TextField
                    name="phoneNumber"
                    label="Phone Number"
                    type="tel"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                    fullWidth
                    margin="normal"
                    sx={{textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)'}}
                />
                <TextField
                    name="message"
                    label="Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    sx={{textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)'}}
                />
                <Typography sx={{color: 'red',}}>{errorMessage}</Typography>
                <Button type="submit" variant="contained" color="primary" disabled={formSubmitting}>
                    {formSubmitting ? <CircularProgress/> : "Submit"}
                </Button>
            </form>
    );
}

export default ContactForm;
